import React, { useEffect, useState, useCallback } from "react";
import Section from "../../components/section/Section";
import SectionHeading from "../../components/typography/SectionHeading";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";
import useFarmer from "../../hooks/farmer/useFarmer";
import useMilk from "../../hooks/milk/useMilk";
import Form from "../../components/form/Form";
import FormInput from "../../components/form/FormInput";
import FormInputHolder from "../../components/form/FormInputHolder";
import PrintRES from "./milk/PrintRES";
import PrintC from "./milk/PrintC";

function calculateSum(data) {
  let sum = 0;
  for (let i = 0; i < data.length; i++) {
    sum += data[i].amount;
  }
  return sum;
}

const PrintMilk = () => {
  const [totalMilkFarm, setTotalMilkFarm] = useState(0);
  const [totalMilkNoFarm, setTotalMilkNoFarm] = useState(0);
  const [milkFarmPrice, setMilkFarmPrice] = useState(45);
  const [milkNoFarmPrice, setMilkNoFarmPrice] = useState(55);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [certificateStart, setCertificateStart] = useState(1);
  const [milkQualities, setMilkQualities] = useState([]);

  const { farmers, farmersLoading } = useFarmer();
  const { milks, milksLoading, milksRefetch } = useMilk(
    `date_start=${startDate}&date_end=${endDate}`
  );

  useEffect(() => {
    function getStartEndDate() {
      const givenDate = new Date();
      console.log(givenDate);
      const year = givenDate.getFullYear();
      const month = givenDate.getMonth();
      const day = givenDate.getDate();

      let startDate, endDate;

      if (day <= 15) {
        startDate = new Date(year, month - 1, 16);
        endDate = new Date(year, month, 0);
      } else {
        startDate = new Date(year, month, 1);
        endDate = new Date(year, month, 15);
      }

      const formatDate = (date) => {
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${date.getFullYear()}-${month}-${day}`;
      };

      return {
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
      };
    }

    const { startDate, endDate } = getStartEndDate();
    setStartDate(startDate);
    setEndDate(endDate);
  }, []);

  useEffect(() => {
    if (milks && milks.length > 0) {
      let totalMilkFarm = 0;
      let totalMilkNoFarm = 0;
      milks.map((milk) => {
        const milkAmount = milk.amount;
        const milkFarm = milk.farm;
        if (milkFarm) {
          totalMilkFarm += milkAmount;
        } else {
          totalMilkNoFarm += milkAmount;
        }
      });
      setTotalMilkFarm(totalMilkFarm);
      setTotalMilkNoFarm(totalMilkNoFarm);
    }
  }, [milks]);

  const handleStartDateChange = useCallback((e) => {
    setStartDate(e.target.value);
    milksRefetch();
  }, []);

  const handleEndDateChange = useCallback((e) => {
    setEndDate(e.target.value);
    milksRefetch();
  }, []);

  const handleMilkFarmPriceChange = useCallback((e) => {
    setMilkFarmPrice(e.target.value);
  }, []);

  const handleMilkNoFarmPriceChange = useCallback((e) => {
    setMilkNoFarmPrice(e.target.value);
  }, []);
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Štampaj - Mleko</SectionHeading>
      </SectionHead>
      <SectionContent>
        <div className="flex flex-wrap items-stretch gap-2 mb-4">
          <div className="bg-theme-color--primary flex-1 flex flex-col gap-2 items-center justify-center p-4 rounded text-theme-color--primaryContrast text-center">
            <span className="font-bold">Ukupno</span>
            <div className="flex flex-col items-center justify-center">
              <span>{totalMilkFarm + totalMilkNoFarm} L</span>
              <span>
                {totalMilkFarm * milkFarmPrice +
                  totalMilkNoFarm * milkNoFarmPrice}{" "}
                RSD
              </span>
            </div>
          </div>

          <div className="bg-slate-900 flex-1 flex flex-col gap-2 items-center justify-center p-4 rounded text-theme-color--primaryContrast text-center">
            <span className="font-bold">Sa PG</span>
            <div className="flex flex-col items-center justify-center">
              <span>{totalMilkFarm} L</span>
              <span>{totalMilkFarm * milkFarmPrice} RSD</span>
            </div>
          </div>

          <div className="bg-slate-700 flex-1 flex flex-col gap-2 items-center justify-center p-4 rounded text-theme-color--primaryContrast text-center">
            <span className="font-bold">Bez PG</span>
            <div className="flex flex-col items-center justify-center">
              <span>{totalMilkNoFarm} L</span>
              <span>{totalMilkNoFarm * milkNoFarmPrice} RSD</span>
            </div>
          </div>
        </div>
        <Form>
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={startDate}
            placeholder={"Početni Datum"}
            onChange={handleStartDateChange}
          />
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={endDate}
            placeholder={"Završni Datum"}
            onChange={handleEndDateChange}
          />
          <FormInput
            icon={"sack-dollar"}
            value={milkFarmPrice}
            placeholder={"Cena (Sa PG)"}
            onChange={handleMilkFarmPriceChange}
          />
          <FormInput
            icon={"sack-dollar"}
            value={milkNoFarmPrice}
            placeholder={"Cena (Bez PG)"}
            onChange={handleMilkNoFarmPriceChange}
          />
        </Form>

        {/* <span>Mleko Sa PG: {totalMilkFarm}L</span>
        <span>Mleko Bez PG: {totalMilkNoFarm}L</span> */}
        <br />
        {farmersLoading || milksLoading ? (
          "Učitava se"
        ) : (
          <>
            {farmers && farmers.length > 0 && milks && milks.length > 0 && (
              <>
                <PrintRES
                  farmers={farmers}
                  milks={milks}
                  milkFarmPrice={milkFarmPrice}
                  milkNoFarmPrice={milkNoFarmPrice}
                  startDate={startDate}
                  endDate={endDate}
                  certificateStart={certificateStart}
                  milkQualities={milkQualities}
                />
                <Form>
                  <FormInput
                    icon={"sack-dollar"}
                    value={certificateStart}
                    placeholder={"Počni od priznanice br."}
                    onChange={(e) => {
                      setCertificateStart(parseInt(e.target.value));
                    }}
                  />
                  <FormInputHolder>
                    {farmers.map((farmer) => {
                      const farmerFarm = farmer.farm;
                      if (farmerFarm) {
                        const farmerID = farmer._id;
                        const farmerMilks = milks.filter(
                          (milk) => milk.farmer._id === farmerID
                        );
                        if (
                          farmerMilks &&
                          farmerMilks.length > 0 &&
                          calculateSum(farmerMilks) > 0
                        ) {
                          const farmerFirstName = farmer.first_name;
                          const farmerLastName = farmer.last_name;
                          return (
                            <FormInput
                              key={farmerID}
                              type="number"
                              icon="droplet"
                              value={milkQualities[farmerID] || 4}
                              placeholder={`${farmerFirstName} ${farmerLastName} - m.m %`}
                              onChange={(e) => {
                                const newMilkQualities = { ...milkQualities };
                                newMilkQualities[farmerID] = parseFloat(
                                  e.target.value
                                );
                                setMilkQualities(newMilkQualities);
                              }}
                            />
                          );
                        }
                      }
                    })}
                  </FormInputHolder>
                </Form>
                <PrintC
                  farmers={farmers}
                  milks={milks}
                  milkFarmPrice={milkFarmPrice}
                  milkNoFarmPrice={milkNoFarmPrice}
                  startDate={startDate}
                  endDate={endDate}
                  certificateStart={certificateStart}
                  milkQualities={milkQualities}
                />
              </>
            )}
          </>
        )}
      </SectionContent>
    </Section>
  );
};

export default PrintMilk;
