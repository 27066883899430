import {
  Document,
  Font,
  Image,
  PDFViewer,
  Page,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useContext } from "react";
import toSizePDF from "../../../functions/toSizePDF";
import toDateReadable from "../../../functions/toDateReadable";
import toDateFormattedDayMonth from "../../../functions/toDateFormattedDayMonth";

import logo from "../../../assets/media/logo.png";
import PoppinsRegular from "../../../assets/fonts/PoppinsRegular-400.ttf";
import PoppinsBold from "../../../assets/fonts/PoppinsBold-700.ttf";
import { MilkPrintContext } from "../../../context/MilkPrintContextProvider";
import toDateFormated from "../../../functions/toDateFormated";

Font.register({
  family: "Poppins",
  src: PoppinsRegular,
});

Font.register({
  family: "Poppins",
  src: PoppinsBold,
});

function calculateSum(data) {
  let sum = 0;
  for (let i = 0; i < data.length; i++) {
    sum += data[i].amount;
  }
  return sum;
}

const ReceiptPDF = ({
  farmers,
  milks,
  milkFarmPrice,
  milkNoFarmPrice,
  startDate,
  endDate,
}) => {
  let farmerNum = 0;

  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  const dates = [];

  for (
    let date = startDateObj;
    date <= endDateObj;
    date.setDate(date.getDate() + 1)
  ) {
    dates.push(toDateFormated(new Date(date)));
  }
  return (
    <Document
      title={`Mleko - Obračuni ( ${toDateReadable(
        new Date(startDate)
      )} - ${toDateReadable(new Date(endDate))} )`}
    >
      {farmers.map((farmer) => {
        let milkTotal = 0,
          priceTotal = 0;
        const farmerID = farmer._id;
        const farmerFirstName = farmer.first_name;
        const farmerLastName = farmer.last_name;
        const farmerMilks = milks.filter(
          (milk) => milk.farmer._id === farmerID
        );
        if (
          farmerMilks &&
          farmerMilks.length > 0 &&
          calculateSum(farmerMilks) > 0
        ) {
          farmerNum += 1;
          return (
            <Page
              size={{ width: toSizePDF(70), height: toSizePDF(148.5) }}
              style={{
                backgroundColor: "#fff",
                fontFamily: "Poppins",
              }}
            >
              <View
                style={{
                  fontSize: 7.5,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 3,
                }}
              >
                <Image
                  src={logo}
                  style={{
                    width: 80,
                    marginTop: 3,
                    marginBottom: 3,
                  }}
                />
                <Text>PR Mlekara Eco Milk Pešter</Text>
                <Text>Ivanča 54 Novi Pazar 36308</Text>
              </View>
              <View>
                <Text
                  style={{
                    fontSize: 13,
                    textAlign: "center",
                  }}
                >
                  {farmerNum}. {farmerFirstName} {farmerLastName}
                </Text>
                <Text
                  style={{
                    fontSize: 7.5,
                    textAlign: "center",
                    marginTop: -3,
                  }}
                >
                  {toDateReadable(new Date(startDate))}
                  {" - "}
                  {toDateReadable(new Date(endDate))}
                </Text>

                <View
                  style={{
                    fontSize: 8.5,
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 3,
                  }}
                >
                  <View
                    style={{
                      height: toSizePDF(7),
                      width: toSizePDF(20),
                      borderLeft: "1px solid #000",
                      borderRight: "0.5px solid #000",
                      borderTop: "1px solid #000",
                      backgroundColor: "#13007c",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text>Datum</Text>
                  </View>
                  <View
                    style={{
                      height: toSizePDF(7),
                      width: toSizePDF(20),
                      borderLeft: "0.5px solid #000",
                      borderRight: "1px solid #000",
                      borderTop: "1px solid #000",
                      borderBottom: "1px solid #000",
                      backgroundColor: "#13007c",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text>Količina</Text>
                  </View>
                </View>

                <View
                  style={{
                    width: toSizePDF(40),
                    borderBottom: "1px solid #000",
                    margin: "0 auto",
                  }}
                >
                  {dates.map((date, index) => {
                    const milkDates = farmerMilks.filter(
                      (milk) => toDateFormated(new Date(milk.date)) === date
                    );
                    const milkDate = milkDates[0];
                    const fill = index % 2 === 1;
                    const milkAmount = milkDate ? milkDate.amount : 0;
                    const milkFarm = milkDate ? milkDate.farm : false;
                    const milkPrice = milkFarm
                      ? milkFarmPrice
                      : milkNoFarmPrice;
                    milkTotal += milkAmount;
                    priceTotal += milkAmount * milkPrice;
                    return (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: 9,
                        }}
                      >
                        <View
                          style={{
                            height: toSizePDF(5),
                            width: toSizePDF(20),
                            borderLeft: "1px solid #000",
                            borderRight: "0.5px solid #000",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: fill ? "#e0ebff" : "#fff",
                          }}
                        >
                          <Text>{toDateFormattedDayMonth(date)}</Text>
                        </View>
                        <View
                          style={{
                            height: toSizePDF(5),
                            width: toSizePDF(20),
                            borderLeft: "0.5px solid #000",
                            borderRight: "1px solid #000",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: fill ? "#e0ebff" : "#fff",
                          }}
                        >
                          <Text>{milkAmount} L</Text>
                        </View>
                      </View>
                    );
                  })}
                </View>

                <View
                  style={{
                    fontSize: 8.5,
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 5,
                  }}
                >
                  <View
                    style={{
                      height: toSizePDF(7),
                      width: toSizePDF(20),
                      borderLeft: "1px solid #000",
                      borderRight: "0.5px solid #000",
                      borderBottom: "1px solid #000",
                      borderTop: "1px solid #000",
                      backgroundColor: "#13007c",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text>Cena</Text>
                  </View>
                  <View
                    style={{
                      height: toSizePDF(7),
                      width: toSizePDF(20),
                      borderLeft: "0.5px solid #000",
                      borderRight: "1px solid #000",
                      borderBottom: "1px solid #000",
                      borderTop: "1px solid #000",
                      backgroundColor: "#13007c",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text>Količina</Text>
                  </View>
                </View>
                <View
                  style={{
                    height: toSizePDF(5),
                    width: toSizePDF(40),
                    margin: "0 auto",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    borderBottom: "1px solid #000",
                    fontSize: 9,
                  }}
                >
                  <View
                    style={{
                      height: toSizePDF(5),
                      width: toSizePDF(20),
                      borderLeft: "1px solid #000",
                      borderRight: "0.5px solid #000",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text>{priceTotal / milkTotal} RSD</Text>
                  </View>
                  <View
                    style={{
                      height: toSizePDF(5),
                      width: toSizePDF(20),
                      borderLeft: "0.5px solid #000",
                      borderRight: "1px solid #000",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text>{milkTotal} L</Text>
                  </View>
                </View>

                <View
                  style={{
                    fontSize: 8.5,
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 5,
                  }}
                >
                  <View
                    style={{
                      height: toSizePDF(7),
                      width: toSizePDF(40),
                      border: "1px solid #000",
                      backgroundColor: "#13007c",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text>Iznos</Text>
                  </View>
                  <View
                    style={{
                      height: toSizePDF(5),
                      width: toSizePDF(40),
                      borderLeft: "1px solid #000",
                      borderRight: "0.5px solid #000",
                      borderBottom: "1px solid #000",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text>{priceTotal} RSD</Text>
                  </View>
                </View>
              </View>
            </Page>
          );
        }
      })}
    </Document>
  );
};

export default ReceiptPDF;
