import React, { useEffect, useState } from "react";
import Section from "../../components/section/Section";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";
import Form from "../../components/form/Form";
import FormInput from "../../components/form/FormInput";
import FormSelect from "../../components/form/FormSelect";
import FormSubmit from "../../components/form/FormSubmit";
import apiRequest from "../../api/apiRequest";
import FormError from "../../components/form/FormError";
import SectionHeading from "../../components/typography/SectionHeading";
import toDateFormated from "../../functions/toDateFormated";
import toMilkSelect from "../../functions/toMilkSelect";
import useMilk from "../../hooks/milk/useMilk";
import LoadingData from "../../components/data/LoadingData";
import FormInputHolder from "../../components/form/FormInputHolder";
import FormNotice from "../../components/form/FormNotice";
import FormLink from "../../components/form/FormLink";

const MilkEdit = () => {
  const [formLoading, setLoadingData] = useState(false);
  const [formError, setFormError] = useState();

  const [milkDate, setMilkDate] = useState(toDateFormated());
  const [milkID, setMilkID] = useState();
  const [milkAmount, setMilkAmount] = useState();

  const { milks, milksLoading, milksError, milksRefetch } = useMilk(
    `date_start=${milkDate}&date_end=${milkDate}`
  );

  const milkSelect = toMilkSelect(milks);

  useEffect(() => {
    if (milks && milks.length > 0) {
      setMilkID(milks[0]._id);
    }
  }, [milks]);

  useEffect(() => {
    milksRefetch();
  }, [milkDate]);

  const handleFormSubmit = async () => {
    setLoadingData(true);
    setFormError(false);
    const request = {
      id: milkID,
      amount: milkAmount,
    };
    const response = await apiRequest.post("/milk/edit", request);
    setLoadingData(false);
    if (response.status_code === 200) {
      window.location.reload();
    } else {
      setFormError(response.error);
    }
  };

  const handleFormChange = () => {
    setFormError(null);
  };
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Mleko - Uredi</SectionHeading>
      </SectionHead>
      <SectionContent>
        {milksLoading ? (
          <LoadingData />
        ) : (
          <Form>
            <FormInput
              type="date"
              icon={"calendar-day"}
              value={milkDate}
              placeholder={"Datum"}
              onChange={(e) => {
                handleFormChange();
                setMilkDate(e.target.value);
              }}
            />

            {milks && milks.length > 0 ? (
              <>
                <FormInputHolder>
                  <FormSelect
                    placeholder={"Mleko"}
                    options={milkSelect}
                    onChange={(e) => {
                      handleFormChange();
                      setMilkID(e.target.value);
                    }}
                  />
                  <FormInput
                    icon={"scale-balanced"}
                    value={milkAmount}
                    placeholder={"Novi Iznos"}
                    onChange={(e) => {
                      handleFormChange();
                      setMilkAmount(e.target.value);
                    }}
                  />
                </FormInputHolder>
                {formError ? (
                  <FormError>{formError}</FormError>
                ) : (
                  <FormSubmit
                    onClick={() => handleFormSubmit()}
                    isLoading={formLoading}
                  >
                    Uredi
                  </FormSubmit>
                )}
              </>
            ) : (
              <>
                <FormNotice>Nema Mleka za Uređivanje</FormNotice>
                <FormLink to={"/milk/add"}>Dodaj Mleko</FormLink>
              </>
            )}
          </Form>
        )}
      </SectionContent>
    </Section>
  );
};

export default MilkEdit;
